import styled from "@emotion/styled";

export const ErrorPage = styled.section`
  .wrapper {
    min-height: 500px;
  }

  h1 {
    --min: 3.125em;
    --val: 5.2vw;
    --max: 6.25em;
    margin-bottom: 1.25rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.green};
  }

  p {
    max-width: 500px;
    text-align: center;
  }
`;
