import { NextPage } from "next";
import Link from "next/link";
import FooterComponent from "../components/footer.component";
import HeaderComponent from "../components/header/header.component";
import { Button } from "../styles/components/button.emotion";
import { ErrorPage } from "../styles/pages/error.emotion";
import { Container, Wrapper } from "../styles/utils/global.emotion";
import { Description } from "../styles/utils/typograth.emotion";

const NotFoundPage: NextPage = () => {
  return (
    <>
      <HeaderComponent />
      <ErrorPage>
        <Container size="sm">
          <Wrapper
            alignItems="center"
            justifyContent="center"
            className="wrapper"
            flexDirection="column"
            gap="16px"
          >
            <h1>404</h1>
            <Description>
              <b>Desculpe, não encontramos nada nesta página!</b> Clique no
              botão abaixo para voltar ao início.
            </Description>
            <Link href="/" passHref>
              <Button buttonStyle="default" as="a">
                <span>Voltar ao início</span>
              </Button>
            </Link>
          </Wrapper>
        </Container>
      </ErrorPage>
      <FooterComponent />
    </>
  );
};

export default NotFoundPage;
